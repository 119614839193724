











import Vue from 'vue';

export default Vue.extend({
  name: 'cs-icon',
  props: {
    icon: { type: String, default: null },
    animate: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    color: { type: String, default: null },
  },
});
