<template>
  <component
    :is="icon"
    viewBox="0 0 24 24"
    fill="currentColor"
    :style="styles"
  />
</template>

<script>
export default {
  name: 'cs-icon-base',
  props: {
    icon: Object,
    styles: Object,
  },
};
</script>
