import { render, staticRenderFns } from "./ClockAnalogue.vue?vue&type=template&id=4dea6038&scoped=true&"
import script from "./ClockAnalogue.vue?vue&type=script&lang=ts&"
export * from "./ClockAnalogue.vue?vue&type=script&lang=ts&"
import style0 from "./ClockAnalogue.vue?vue&type=style&index=0&id=4dea6038&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dea6038",
  null
  
)

export default component.exports