








import Vue from 'vue';
import CsSnackbarManager from '../CsSnackbar/CsSnackbarManager.vue';

export default Vue.extend({
  components: {
    CsSnackbarManager,
  },
});
