







import { WidgetData } from '../../models/data';
import { WidgetType } from '../../models/widgetType';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'Picture',
  props: {
    bodyData: {
      type: Object as PropType<WidgetData[WidgetType.Picture]>,
      required: true,
    },
  },
});
