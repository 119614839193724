









import Vue from 'vue';

export default Vue.extend({
  name: 'cs-toolbar',
  props: {
    extensionHeight: { type: Number },
  },
});
