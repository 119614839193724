































import Vue from 'vue';

export default Vue.extend({
  name: 'cs-birthday-picker',
  props: {
    label: { type: String },
    value: { type: String },
  },
  data: () => ({
    activePicker: null as string | null,
    date: null,
    menu: false,
  }),
  computed: {
    isoDate() {
      return this.value && new Date(this.value).toISODateString();
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
  },
  methods: {
    save(date: string) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.menu as any).save(date);
    },
    updateValue(val: string) {
      this.$emit('input', val);
    },
  },
});
