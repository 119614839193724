










import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    show: false,
  }),
  computed: {
    displayType() {
      return this.show ? 'text' : 'password';
    },
    icon() {
      return this.show ? '$vuetify.icons.Invisible' : '$vuetify.icons.Visible';
    },
  },
  methods: {
    clickHandler() {
      this.show = !this.show;
    },
  },
});
