
















import Vue from 'vue';

export default Vue.extend({
  name: 'cs-simple-list',
  props: {
    items: { type: Array },
    display: { type: String, default: 'label' },
  },
  methods: {
    itemClicked(item: unknown) {
      this.$emit('itemClicked', item);
    },
  },
});
