










import Vue from 'vue';

export default Vue.extend({
  name: 'cs-checkbox',
  props: {
    label: { type: String, default: '' },
    value: { type: Boolean, default: false },
    name: { type: String },
  },
  methods: {
    onInput(val: unknown) {
      this.$emit('input', val);
    },
  },
});
