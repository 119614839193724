












import Vue from 'vue';

export default Vue.extend({
  name: 'cs-button',
  props: {
    active: { type: Boolean, default: false },
    color: { type: String, default: '' },
  },
});
