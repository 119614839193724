var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.baseComponent,{ref:"input",tag:"component",attrs:{"items":_vm.search ? _vm.results : _vm.options,"multiple":_vm.multiple,"label":_vm.label,"item-text":_vm.itemText,"item-value":_vm.itemValue,"return-object":_vm.returnObject,"loading":_vm.isLoading,"search-input":_vm.search,"no-data-text":_vm.$t('NO_DATA_AVAILABLE'),"chips":"","deletable-chips":"","hide-no-data":_vm.isLoading,"value":_vm.value,"no-filter":"","cache-items":"","menu-props":{
    allowOverflow: true,
  },"rules":_vm.rules.concat( (_vm.required && !_vm.disabled ? [_vm.validate] : []))},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":_vm.updateValue},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
  var parent = ref.parent;
return [(index < _vm.maxItems)?_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){_vm.multiple ? parent.selectItem(item) : _vm.updateValue(null)}}},[_c('span',[_vm._v(_vm._s(_vm.getText(item)))])]):_vm._e(),(index === _vm.maxItems)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" ("+_vm._s(_vm.$t('ADDITIONAL_ITEMS', { count: _vm.value.length - _vm.maxItems }))+") ")]):_vm._e()]}},{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.getText(item)))]),(_vm.getSubtitle(item))?_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.getSubtitle(item)))]):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }