













import Vue from 'vue';
import { NotificationService } from '../../services';
import {
  NotificationData,
  NotificationHandle,
  NotificationResult,
} from '../../services/NotificationService';
import CsSnackbar from './CsSnackbar.vue';

interface Notification {
  key: number;
  data: NotificationData;
  visible: boolean;
  resolve?: (value: NotificationResult) => void;
}

export default Vue.extend({
  name: 'cs-snackbar-manager',
  components: { CsSnackbar },
  data: () => ({
    notifications: [] as Notification[],
    key: 0,
  }),
  created() {
    NotificationService.registerHandler(this.showNotification);
  },
  methods: {
    showNotification(notification: NotificationData): NotificationHandle {
      const item: Notification = {
        data: notification,
        visible: true,
        resolve: undefined,
        key: ++this.key,
      };

      const promise = new Promise<NotificationResult>((resolve) => {
        item.resolve = resolve;
        this.notifications.push(item);
      });

      return {
        hide: () => this.hide(item.key, false),
        getResult: () => promise,
      };
    },
    hide(key: number, clicked: boolean) {
      const notification = this.notifications.find((x) => x.key === key);
      if (notification) {
        notification.visible = false;

        if (notification.resolve) {
          notification.resolve({ buttonClicked: clicked });
        }

        setTimeout(() => {
          this.notifications = this.notifications.filter((x) => x.key !== key);
        }, 200);
      }
    },
  },
});
