import { AuthService } from '@consolidate/shared/util-auth';
import { NavigationGuardNext, Route } from 'vue-router';

export async function authGuard<V extends Vue = Vue>(
  to: Route,
  from: Route,
  next: NavigationGuardNext<V>
): Promise<unknown> {
  if (
    !AuthService.isLoggedIn() &&
    to.name !== 'login' &&
    to.name !== 'login-callback'
  ) {
    const url = AuthService.authority;
    if (url) {
      await AuthService.login(url);
    }
    return next({ name: 'login' });
  }

  return next();
}
