











import Vue from 'vue';

export default Vue.extend({
  name: 'cs-phone-input',
  props: {
    label: { type: String, default: '' },
    value: { type: [String, Number], default: null },
    required: { type: Boolean, default: false },
  },
  data: () => ({
    show: false,
  }),
  methods: {
    updateValue(val: string | number) {
      this.$emit('input', val);
    },
    validate(value: unknown) {
      if (value instanceof Array) {
        return Boolean(value.length);
      } else {
        return !!value;
      }
    },
    phonenNumber(value: string) {
      if (!value) return true;
      const pattern = /^([\d() +-]+)$/;
      return pattern.test(value) || this.$t('INVALID_PHONE_NUMBER');
    },
  },
});
