















import Vue from 'vue';
import animationData from './15-growth.json';

export default Vue.extend({
  props: {
    animationData: { type: Object, default: () => animationData },
  },
});
