import { getLang } from '@consolidate/shared/util-translations';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Date {
    toISODateString(): string;
    toLocaleDateTimeStringLS(): string;
    toLocaleDateStringLS(options?: Intl.DateTimeFormatOptions): string;
    toLongDateString(): string;
    toShortDateString(): string;
    isToday(): boolean;
    toShortTimeString(): string;
    set(options: {
      minutes: number;
      hours: number;
      days: number;
      months: number;
      years: number;
    }): Date;
    getWeek(): number;
    addDays(days: number): Date;
  }

  interface DateConstructor {
    min(...dates: Date[]): Date;
    max(...dates: Date[]): Date;
  }

  interface Array<T> {
    sortAsc(key: keyof T): Array<T>;
    sortDesc(key: keyof T): Array<T>;
    searchObjects(properties: (keyof T)[], filter: string): T[];
    groupBy<P extends string | number>(
      callback: (item: T) => P
    ): Record<P, T[]>;
    unique(): Array<T>;
  }
}

export function registerPrototypeExtensions(): void {
  /**
   * Changes the date into a date string of format yyyy-mm-dd
   * It will not convert time to utc or vice versa, just as is
   */
  Date.prototype.toISODateString = function toISODateString() {
    const year = this.getFullYear();
    const month = String(this.getMonth() + 1).padStart(2, '0');
    const day = String(this.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  /**
   * Gets the locale from local storage instead of browser.
   * Then uses that locale to format the localeDatetimeString
   */
  Date.prototype.toLocaleDateTimeStringLS = function () {
    const locale = getLang();

    return `${this.toLocaleDateString(locale)} ${this.toLocaleTimeString(
      locale,
      {
        hour: '2-digit',
        minute: '2-digit',
      }
    )}`;
  };

  /**
   * Gets the locale from local storage instead of browser.
   * Then uses that locale to format the localeDateString
   */
  Date.prototype.toLocaleDateStringLS = function (
    options?: Intl.DateTimeFormatOptions
  ) {
    const locale = getLang();

    return this.toLocaleDateString(locale, options);
  };

  /**
   * Gives the local date without changing the date based on timezones
   * @returns {String} The date as 'Montag, 25.02.1990'
   */
  Date.prototype.toLongDateString = function toLongString() {
    const asUtc = new Date(
      Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())
    );
    const locale = getLang();
    return asUtc.toLocaleDateString(locale, {
      weekday: 'long',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  };

  Date.prototype.toShortDateString = function toLongString() {
    const asUtc = new Date(
      Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())
    );
    const locale = getLang();
    return asUtc.toLocaleDateString(locale, {
      weekday: 'short',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  };

  /**
   * Checks if the date is the same as the current date
   * @returns {Boolean} true when the date is the same as today
   */
  Date.prototype.isToday = function isToday() {
    const asUtc = Date.UTC(this.getFullYear(), this.getMonth(), this.getDate());
    const now = new Date();
    const nowAsUtc = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
    return nowAsUtc === asUtc;
  };

  /**
   * Gives the time part as string hh:mm
   * @returns {String} the hours and minutes separated by semicolon "20:01";
   */
  Date.prototype.toShortTimeString = function toShortTimeString() {
    const [hours, minutes] = [this.getHours(), this.getMinutes()].map((f) =>
      f.toString().padStart(2, '0')
    );
    return `${hours}:${minutes}`;
  };

  Date.prototype.set = function set(options) {
    const { minutes, hours, days, months, years } = options;

    if (minutes === 0) {
      this.setMinutes(0);
    }
    if (minutes) {
      this.setMinutes(this.getMinutes() + minutes);
    }

    if (hours) {
      this.setHours(this.getHours() + hours);
    }

    if (days) {
      this.setDate(this.getDate() + days);
    }
    if (months) {
      this.setMonth(this.getMonth() + months);
    }
    if (years) {
      this.setFullYear(this.getFullYear() + years);
    }
    return this;
  };

  /**
   * Gets the weeknumber for this date.
   * https://github.com/antoniandre/vue-cal/blob/master/src/vue-cal/utils/date.js
   * @returns {Number} the number of the week
   */
  Date.prototype.getWeek = function () {
    const d = new Date(
      Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  };

  Date.prototype.addDays = function (days) {
    this.setDate(this.getDate() + days);
    return this;
  };

  Date.min = function (...dates: Date[]) {
    return dates.sort((a, b) => a.getTime() - b.getTime())[0];
  };

  Date.max = function (...dates: Date[]) {
    return dates.sort((a, b) => a.getTime() - b.getTime())[dates.length - 1];
  };

  /**
   * Sorts the array ascending and returns a new array with the results
   * @param {String} key property name when array items are of type Object
   * @example ["c","a"].sortAsc();
   * @example [{name:"c"},{name:"a"}].sortAsc("name");
   * @example const sortedItems = items.sortAsc("firstName");
   * @returns {Array} new Array
   */
  Array.prototype.sortAsc = function sortAsc(key) {
    if (this.length === 0) return this;
    const first = this[0];
    if (typeof first === 'object') {
      return this.sort((a, b) => {
        const propA = a[key]?.toString().toUpperCase();
        const propB = b[key]?.toString().toUpperCase();
        if (propA < propB) {
          return -1;
        }
        if (propA > propB) {
          return 1;
        }
        return 0;
      });
    }
    return this.sort((a, b) => {
      const propA = a?.toUpperCase();
      const propB = b?.toUpperCase();
      if (propA < propB) {
        return -1;
      }
      if (propA > propB) {
        return 1;
      }
      return 0;
    });
  };

  /**
   * Sorts the array descending and returns a new array with the results
   * @param {String} key property name when array items are of type Object
   * @see sortAsc
   */
  Array.prototype.sortDesc = function sortAsc(key) {
    return this.sortAsc(key).reverse();
  };

  /**
   * Filters the array based on the filter given.
   * @param {Array} properties the properties of the object to find
   * @param {String} filter the term to search for in the properties
   * @returns {Array} result array with the objects that contains the term in one of the properties
   */
  Array.prototype.searchObjects = function (properties, filter) {
    const result = [];
    const terms = filter.split(' ').filter(Boolean);
    for (const item of this) {
      // eslint-disable-next-line no-continue
      if (item.isDivider) continue;

      let matchCount = 0;
      for (const term of terms) {
        for (const prop of properties) {
          if (item[prop]?.toLowerCase()?.includes(term)) {
            matchCount += 1;
            break;
          }
        }
      }

      if (matchCount === terms.length) result.push(item);
    }

    return result;
  };

  Array.prototype.groupBy = function (callback) {
    return this.reduce((rv, x) => {
      const key = callback(x);
      (rv[key] = rv[key] || []).push(x);
      return rv;
    }, {});
  };

  Array.prototype.unique = function () {
    return [...new Set(this)];
  };
}
