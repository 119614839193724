import { AuthService } from '@consolidate/shared/util-auth';
import { AxiosResponse } from 'axios';
import { Widget } from '../models/widget';
import httpClient from './httpClient';

const END_POINT = '/api/v1/widgets';

export const getWidgets = (): Promise<AxiosResponse<Widget[]>> =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  httpClient.get(END_POINT, { baseURL: AuthService.getWebServiceUrl()! });

export const getWidgetBody = (key: number): Promise<AxiosResponse> =>
  httpClient.get(`${END_POINT}/${key}`, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    baseURL: AuthService.getWebServiceUrl()!,
  });
