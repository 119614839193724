






















import Vue from 'vue';

export default Vue.extend({
  name: 'cs-input',
  props: {
    label: { type: String, default: '' },
    hint: { type: String, default: '' },
    type: { type: String, default: 'text' },
    value: { type: [String, Number], default: null },
    required: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessages: { type: Array },
    name: { type: String },
    appendIcon: { type: String },
    autofocus: { type: Boolean },
  },
  data: () => ({
    show: false,
  }),
  methods: {
    updateValue(val: string | number) {
      this.$emit('input', val);
    },
    validate(value: unknown) {
      if (value instanceof Array) {
        return Boolean(value.length);
      } else if (this.type === 'number') {
        return value != null && value != '';
      } else {
        return !!value;
      }
    },
    focus() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.input as any).focus();
    },
    blur() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.input as any).blur();
    },
  },
});
