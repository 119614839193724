




import { WidgetData } from '../../models/data';
import { WidgetType } from '../../models/widgetType';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'Website',
  props: {
    bodyData: {
      type: Object as PropType<WidgetData[WidgetType.Website]>,
      required: true,
    },
  },
  data: () => ({
    interval: {} as NodeJS.Timeout,
  }),
  created() {
    this.interval = setInterval(() => {
      this.reload();
    }, 60 * 1000 * 5); // reload the iframe every 5 minutes.
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    reload() {
      const iframe = this.$refs.website as HTMLIFrameElement;
      iframe.src += '';
    },
  },
});
