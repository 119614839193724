










































import { WidgetData } from '../../models/data';
import { WidgetType } from '../../models/widgetType';
import Vue, { PropType } from 'vue';
import moment, { Moment } from 'moment-timezone';

export default Vue.extend({
  name: 'ClockAnalogue',
  props: {
    bodyData: {
      type: Object as PropType<WidgetData[WidgetType.ClockAnalogue]>,
      required: true,
    },
  },
  data: () => ({
    moment: {} as Moment,
    seconds: 0,
    // eslint-disable-next-line no-undef
    interval: {} as NodeJS.Timer,
  }),
  mounted() {
    this.tick();
    this.interval = setInterval(this.tick, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    tick() {
      const m = moment().tz(this.bodyData.timezone);
      this.seconds = m.hours() * 60 * 60 + m.minutes() * 60 + m.seconds();
    },
  },
});
