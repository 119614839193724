






import { WidgetData } from '../../models/data';
import { WidgetType } from '../../models/widgetType';
import Vue, { PropType } from 'vue';
import pdf from 'vue-pdf';

export default Vue.extend({
  name: 'Pdf',
  components: {
    pdf,
  },
  props: {
    bodyData: {
      type: Object as PropType<WidgetData[WidgetType.Pdf]>,
      required: true,
    },
  },
  data: () => ({
    src: undefined,
    numPages: 0,
  }),
  watch: {
    bodyData() {
      this.loadPdf();
    },
  },
  mounted() {
    this.loadPdf();
  },
  methods: {
    loadPdf() {
      this.src = pdf.createLoadingTask(
        `data:${this.bodyData.mimeType};base64, ${this.bodyData.base64}`
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.src as any).promise.then((pdf: any) => {
        // we display 10 pages max, else performance is horrible
        this.numPages = Math.min(10, pdf.numPages);
      });
    },
  },
});
