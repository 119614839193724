








import Vue from 'vue';
import lottie from 'lottie-web';

export default Vue.extend({
  name: 'cs-animation',
  props: {
    path: { type: String },
    animationData: { type: Object },
    color: { type: String },
  },
  mounted() {
    lottie.loadAnimation({
      container: this.$refs.animation as HTMLElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: this.animationData,
      path: this.path,
    });
  },
});
