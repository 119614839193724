import { PluginFunction, VueConstructor } from 'vue';
import VueRx from 'vue-rx';
import VueVirtualScroller from 'vue-virtual-scroller';
import * as components from './components';
import { install as installVuetify } from './plugins/vuetify';
import { registerPrototypeExtensions } from './prototypeExtensions';

registerPrototypeExtensions();

export class ComponentsPlugin {
  static installed: boolean;
  static install: PluginFunction<never> = (Vue: VueConstructor) => {
    if (ComponentsPlugin.installed) return;
    ComponentsPlugin.installed = true;

    for (const key in components) {
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      Vue.component(key, (components as any)[key]);
    }

    Vue.use(VueRx);

    Vue.use(VueVirtualScroller);

    installVuetify(Vue);
  };
}
