










import Vue from 'vue';
import animationData from './11-research.json';

export default Vue.extend({
  props: {
    searched: { type: Boolean, default: false },
  },
  data: () => ({
    animationData: animationData,
  }),
});
