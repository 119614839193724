






import { WidgetData } from '../../models/data';
import { WidgetType } from '../../models/widgetType';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ClockDigital',
  props: {
    bodyData: {
      type: Object as PropType<WidgetData[WidgetType.ClockDigital]>,
      required: true,
    },
  },
  data: () => ({
    time: '',
    // eslint-disable-next-line no-undef
    interval: {} as NodeJS.Timer,
  }),
  mounted() {
    this.updateTime();
    this.interval = setInterval(this.updateTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    updateTime() {
      this.time = new Intl.DateTimeFormat('de-AT', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZone: this.bodyData.timezone,
      }).format(new Date());
    },
  },
});
