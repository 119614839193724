











































import Vue from 'vue';
export default Vue.extend({
  name: 'cs-color-picker',
  props: {
    disabled: { type: Boolean, default: false },
    label: { type: String },
    value: { type: String },
  },
  data: () => ({
    menu: false,
  }),
  methods: {
    change(val: string | { hex: string }) {
      if (typeof val === 'object') {
        this.$emit('input', val.hex);
        return;
      }
      this.$emit('input', val);
    },
  },
});
