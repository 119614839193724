
















import { WidgetData } from '../../models/data';
import { WidgetType } from '../../models/widgetType';
import Vue, { PropType } from 'vue';
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';

export default Vue.extend({
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
  },
  props: {
    bodyData: {
      type: Object as PropType<WidgetData[WidgetType.Map]>,
      required: true,
    },
  },
  data() {
    return {
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      options: {
        zoomControl: false,
      },
    };
  },
  computed: {
    bounds() {
      return this.bodyData.markers
        .filter((x) => x.lat && x.lng)
        .map((x) => [x.lat, x.lng]);
    },
    markers() {
      return this.bodyData.markers
        .filter((x) => x.lat && x.lng)
        .map((x) => ({
          latLng: [x.lat, x.lng],
          color: x.color,
          popuptext: x.popupText,
        }));
    },
  },
});
