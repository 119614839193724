











import Vue from 'vue';

export default Vue.extend({
  name: 'cs-select',
  props: {
    itemText: { type: [String, Function], default: 'name' },
    itemValue: { type: String, default: 'id' },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    validate(value: unknown) {
      if (value instanceof Array) {
        return Boolean(value.length);
      } else {
        return value != null && value !== -1;
      }
    },
  },
});
