import { RouteConfig } from 'vue-router';

export const loginRoutes = (
  name: string,
  animation: unknown,
  minVersion: string
): RouteConfig[] => [
  {
    path: '/login',
    name: 'login',
    props: {
      name,
      animation,
      minVersion,
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    component: () =>
      import(/* webpackChunkName: "login" */ './components/Login.vue'),
  },
  {
    path: '/login-callback',
    name: 'login-callback',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    component: () =>
      import(
        /* webpackChunkName: "login-callback" */ './components/LoginCallback.vue'
      ),
  },
];
