













import Vue from 'vue';

export default Vue.extend({
  name: 'cs-autocomplete',
  props: {
    required: { type: Boolean, default: false },
  },
  mounted() {
    const autocomplete = this.$refs.autocomplete as HTMLFormElement;
    autocomplete.onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        autocomplete.isMenuActive = false;
      }
    };
  },
  methods: {
    validate(value: unknown) {
      if (value instanceof Array) {
        return Boolean(value.length);
      } else {
        return !!value;
      }
    },
  },
});
