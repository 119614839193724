import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de from './files/de.json';
import en from './files/en.json';
import { LOCALE_FALLBACK } from './models/language';

const TRANSLATIONS = {
  en,
  de,
};

i18next.use(LanguageDetector).init({
  detection: {
    lookupLocalStorage: 'lang',
  },
  debug: false,
  saveMissing: process.env.NODE_ENV === 'development', // calls save missing key function on backend if key not found
  missingKeyHandler: (lng, ns, key) => {
    console.warn(`Missing translation key for lang '${lng}'; ${key}`);
  },
  fallbackLng: LOCALE_FALLBACK,
  resources: {
    de: { translation: TRANSLATIONS.de },
    en: { translation: TRANSLATIONS.en },
  },
});
export const i18nextConfig = i18next;
