




























import { WidgetData } from '../../models/data';
import { WidgetType } from '../../models/widgetType';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'List',
  props: {
    bodyData: {
      type: Object as PropType<
        WidgetData[WidgetType.ListWithHeaders | WidgetType.ListWithoutHeaders]
      >,
      required: true,
    },
    showHeaders: {
      type: Boolean,
      required: true,
    },
  },
});
