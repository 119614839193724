











import Vue from 'vue';

export default Vue.extend({
  name: 'cs-toggle-button',
  props: {
    value: { type: Boolean, default: false },
    icon: { type: Boolean, default: false },
  },
  methods: {
    click() {
      this.$emit('input', !this.value);
    },
  },
});
