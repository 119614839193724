import VueI18Next from '@panter/vue-i18next';
import moment from 'moment';
import Vue from 'vue';
import { i18nextConfig } from './config';

Vue.use(VueI18Next);
export const i18n = new VueI18Next(i18nextConfig);

export const changeLanguage = (lang: string): void => {
  i18n.i18next.changeLanguage(lang);
  moment.locale(lang);
};
