import { authGuard, loginRoutes } from '@consolidate/shared/feature-login';
import { ComponentsPlugin } from '@consolidate/shared/ui-components';
import { AuthService } from '@consolidate/shared/util-auth';
import { i18n } from '@consolidate/shared/util-translations';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import animationData from './assets/19-building.json';
import Home from './components/Home.vue';
import './registerServiceWorker';

type D = Icon.Default & {
  _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.use(ComponentsPlugin);
Vue.use(VueRouter);

Vue.config.productionTip = false;

const router = new VueRouter({
  mode: 'history',
  routes: [
    ...loginRoutes('Dashboard', animationData, '5.1067'),
    {
      path: '/',
      name: '',
      component: Home,
    },
  ],
});

router.beforeEach(authGuard);

(async () => {
  await AuthService.init('dashboard', [
    'cons.dashboard',
    'openid',
    'offline_access',
  ]);

  new Vue({
    router,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
})();
