
import Vue from 'vue';
import { Line, mixins } from 'vue-chartjs';
import { ReactivePropMixin } from 'vue-chartjs/types/mixins';

export default Vue.extend({
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    // eslint-disable @typescript-eslint/no-explicit-any
    ((this as any) as Line).renderChart(
      ((this as any) as ReactivePropMixin).chartData,
      this.options
    );
  },
});
