




















import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'cs-radio-group',
  props: {
    label: { type: String, default: null },
    value: {},
    options: {
      type: Array as PropType<{ label: string; value: unknown }[]>,
      default: null,
    },
    column: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },
  methods: {
    validate(value: unknown) {
      if (value instanceof Array) {
        return Boolean(value.length);
      } else {
        return value != null && value !== -1;
      }
    },
    onInput(value: unknown) {
      this.$emit('input', value);
    },
  },
});
