














import Vue, { PropType } from 'vue';
import { getWidgetBody } from '../api/widget.api';
import ClockDigital from './widgets/ClockDigital.vue';
import ValueLabels from './widgets/ValueLabels.vue';
import ChartArea from './widgets/ChartArea.vue';
import Map from './widgets/Map.vue';
import Weather from './widgets/Weather.vue';
import Picture from './widgets/Picture.vue';
import ClockAnalogueVue from './widgets/ClockAnalogue.vue';
import ListWithHeadersVue from './widgets/ListWithHeaders.vue';
import ListWithoutHeadersVue from './widgets/ListWithoutHeaders.vue';
import ChartLineVue from './widgets/ChartLine.vue';
import ChartBarVue from './widgets/ChartBar.vue';
import ChartPieVue from './widgets/ChartPie.vue';
import WebsiteVue from './widgets/Website.vue';
import PdfVue from './widgets/Pdf.vue';
import { Widget } from '../models/widget';
import { getWidgetTypeName, WidgetType } from '../models/widgetType';

export default Vue.extend({
  name: 'WidgetShell',
  components: {
    [getWidgetTypeName(WidgetType.ListWithHeaders)]: ListWithHeadersVue,
    [getWidgetTypeName(WidgetType.ListWithoutHeaders)]: ListWithoutHeadersVue,
    [getWidgetTypeName(WidgetType.ClockDigital)]: ClockDigital,
    [getWidgetTypeName(WidgetType.Value)]: ValueLabels,
    [getWidgetTypeName(WidgetType.ChartArea)]: ChartArea,
    [getWidgetTypeName(WidgetType.Map)]: Map,
    [getWidgetTypeName(WidgetType.Weather)]: Weather,
    [getWidgetTypeName(WidgetType.Picture)]: Picture,
    [getWidgetTypeName(WidgetType.ClockAnalogue)]: ClockAnalogueVue,
    [getWidgetTypeName(WidgetType.ChartLine)]: ChartLineVue,
    [getWidgetTypeName(WidgetType.ChartBar)]: ChartBarVue,
    [getWidgetTypeName(WidgetType.ChartPie)]: ChartPieVue,
    [getWidgetTypeName(WidgetType.Website)]: WebsiteVue,
    [getWidgetTypeName(WidgetType.Pdf)]: PdfVue,
  },
  props: {
    widget: {
      type: Object as PropType<Widget>,
      required: true,
    },
  },
  data: () => ({
    bodyData: null,
    // eslint-disable-next-line no-undef
    refresh: {} as NodeJS.Timer,
  }),
  async mounted() {
    this.fetchBody();
    this.refresh = setInterval(
      this.fetchBody,
      this.widget.refreshInterval * 1000
    );
  },
  beforeDestroy() {
    clearInterval(this.refresh);
  },
  methods: {
    async fetchBody() {
      const { data } = await getWidgetBody(this.widget.key);
      this.bodyData = data;
    },
    getName(type: WidgetType) {
      return getWidgetTypeName(type);
    },
  },
});
