






import { WidgetData } from '../../models/data';
import { WidgetType } from '../../models/widgetType';
import Vue, { PropType } from 'vue';
import PieChart from './chart_constructors/ChartPieConstructor.vue';

export default Vue.extend({
  name: 'ChartPie',
  components: { PieChart },
  props: {
    bodyData: {
      type: Object as PropType<WidgetData[WidgetType.ChartPie]>,
      required: true,
    },
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
      },
    },
  }),
  computed: {
    data() {
      return {
        ...this.bodyData,
        datasets: this.bodyData.datasets.map((x) => ({
          ...x,
          fill: false,
        })),
      };
    },
  },
});
