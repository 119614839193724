




import { WidgetData } from '../../models/data';
import { WidgetType } from '../../models/widgetType';
import Vue, { PropType } from 'vue';
import List from './List.vue';

export default Vue.extend({
  name: 'ListWithoutHeaders',
  components: {
    List,
  },
  props: {
    bodyData: {
      type: Object as PropType<WidgetData[WidgetType.ListWithoutHeaders]>,
      required: true,
    },
  },
});
