
















































import Vue, { PropType } from 'vue';

import {
  debounceTime,
  switchMap,
  tap,
  map,
  filter,
  startWith,
} from 'rxjs/operators';
import { VCombobox, VAutocomplete } from 'vuetify/lib';

export default Vue.extend({
  name: 'cs-server-search',
  components: {
    VCombobox,
    VAutocomplete,
  },
  props: {
    label: { type: String },
    value: { type: [Object, Array] },
    itemText: { type: [String, Function], required: true },
    itemSubtitle: { type: [String, Function], required: false },
    itemValue: { type: [String, Function], required: true },
    multiple: { type: Boolean },
    initialOptions: {
      type: Array as PropType<unknown[]>,
      default: () => [],
    },
    findItems: {
      type: Function as PropType<(search: string) => Promise<unknown[]>>,
      required: true,
    },
    allowCustom: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    returnObject: { type: Boolean, default: true },
  },
  data: () => ({
    isLoading: false,
    search: '',
    results: [] as unknown[],
  }),
  computed: {
    options() {
      const items = this.initialOptions;

      const values = Array.isArray(this.value) ? this.value : [this.value];
      if (values.length) {
        for (const value of values) {
          if (
            value &&
            !items.some(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (x: any) => this.getValue(x) === this.getValue(value)
            )
          ) {
            items.push(value);
          }
        }
      }
      return items;
    },
    baseComponent() {
      return this.allowCustom ? 'v-combobox' : 'v-autocomplete';
    },
    maxItems() {
      if (this.$vuetify.breakpoint.mobile) {
        return 2;
      }
      return 5;
    },
  },
  created() {
    const results$ = this.$watchAsObservable(() => this.search).pipe(
      // Only take the newValue
      map((x) => x.newValue),
      // dont search when the value is null (happens when a project is selected)
      filter((x) => !!x && x.length >= 2),
      tap(() => (this.isLoading = true)),
      tap(() => (this.results = [])),
      // Wait 500ms before dispatching the search to not spam the server with requests
      debounceTime(500),
      // We only care about the last result of the search
      switchMap((val) => this.findItems(val)),
      tap(() => (this.isLoading = false)),
      // fill the list initially with the favorites
      startWith(this.initialOptions)
    );
    this.$subscribeTo(results$, (r) => {
      this.results = r;

      // this is a workaround for the vuetify bug
      // https://github.com/vuetifyjs/vuetify/issues/11365

      const selected = Array.isArray(this.value) ? this.value : [this.value];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const input = this.$refs.input as any;
      if (input) {
        input.cachedItems = [...r, ...selected];
      }
    });
  },
  methods: {
    validate(value: unknown) {
      if (value instanceof Array) {
        return Boolean(value.length);
      } else {
        return !!value;
      }
    },
    getValue(item: Record<string, unknown> | string) {
      if (typeof item === 'string') {
        return item;
      } else if (typeof this.itemValue === 'function') {
        return this.itemValue(item);
      }
      return item[this.itemValue];
    },
    getText(item: Record<string, unknown> | string) {
      if (typeof item === 'string') {
        return item;
      } else if (typeof this.itemText === 'function') {
        return this.itemText(item);
      }
      return item[this.itemText];
    },
    getSubtitle(item: Record<string, unknown> | string) {
      if (typeof item === 'string') return;

      if (!this.itemSubtitle) {
        return;
      } else if (typeof this.itemSubtitle === 'function') {
        return this.itemSubtitle(item);
      }
      return item[this.itemSubtitle];
    },
    updateValue(val: unknown) {
      this.$emit('input', val);
      this.search = '';
    },
    focus() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.input as any)?.focus();
    },
  },
});
