















import Vue from 'vue';

export default Vue.extend({
  name: 'cs-textarea',
  props: {
    placeholder: { type: String, default: null },
    label: { type: String, default: '' },
    value: { type: [String, Number], default: null },
    autoGrow: { type: Boolean, default: false },
    rowCount: { type: String, default: '5' },
    counter: { type: Number },
    rules: { type: Array, default: () => [] },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    validate(value: unknown) {
      if (value instanceof Array) {
        return Boolean(value.length);
      } else {
        return !!value;
      }
    },
  },
});
