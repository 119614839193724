



































import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    items: { type: Array as PropType<unknown[]>, required: true },
    keyField: { type: String, required: true },
    loading: { type: Boolean, default: false },
    filterActive: { type: Boolean, default: false },
  },
  methods: {
    onIntersect(entries: IntersectionObserverEntry[]) {
      if (entries[0].isIntersecting && !this.loading) {
        this.$emit('bottomReached');
      }
    },
  },
});
