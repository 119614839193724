import { i18nextConfig } from './config';
import { Language, LOCALE_FALLBACK } from './models/language';

export function getLang(): Language {
  // Lang set by i18n
  const localeOnly = (localStorage.getItem('lang') || '').split(
    '-'
  )[0] as Language;
  return localeOnly || LOCALE_FALLBACK;
}

export function translate(
  key: string,
  options?: Record<string, string | number>
): string {
  return i18nextConfig.t(key, options);
}
