import { translate } from '@consolidate/shared/util-translations';

type NotificationType = 'success' | 'error' | 'warning' | 'info';

export interface NotificationResult {
  buttonClicked: boolean;
}

export interface NotificationData {
  message: string;
  message2?: string;
  type: NotificationType;
  timeout: number;
  buttonText?: string;
  loading: boolean;
}

interface DefaultNotificationProps {
  message: string;
  message2?: string;
  timeout?: number;
  buttonText?: string;
  canUndo?: boolean;
  loading?: boolean;
}

export interface NotificationHandle {
  hide(): void;
  getResult(): Promise<NotificationResult>;
}

class NotificationService {
  private notificationHandler?: (data: NotificationData) => NotificationHandle;

  public registerHandler(
    handler: (data: NotificationData) => NotificationHandle
  ) {
    this.notificationHandler = handler;
  }

  public showNotification(
    options: DefaultNotificationProps & { type: NotificationType }
  ): NotificationHandle {
    if (!this.notificationHandler) {
      throw new Error('no notification handler registerd');
    }

    return this.notificationHandler({
      message: options.message,
      message2: options.message2,
      type: options.type,
      timeout: options.timeout ?? 3000,
      buttonText: options.canUndo ? translate('UNDO') : options.buttonText,
      loading: options.loading ?? false,
    });
  }

  public showSuccess(options: DefaultNotificationProps) {
    return this.showNotification({
      ...options,
      type: 'success',
    });
  }

  public showInfo(options: DefaultNotificationProps) {
    return this.showNotification({ ...options, type: 'info' });
  }

  public showError(options: DefaultNotificationProps) {
    return this.showNotification({ ...options, type: 'error' });
  }

  public showWarning(options: DefaultNotificationProps) {
    return this.showNotification({ ...options, type: 'warning' });
  }
}

export default new NotificationService();
