import { AuthService } from '@consolidate/shared/util-auth';
import axios from 'axios';

const httpClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

httpClient.interceptors.request.use(async (config) => {
  config.headers.Authorization = AuthService.getAccessToken() ?? '';
  return config;
});

httpClient.interceptors.response.use(undefined, async (error) => {
  if (error.response?.status === 401) {
    if (error.config && !(error.config as any).__isRetryRequest) {
      await AuthService.refreshToken();

      (error.config as any).__isRetryRequest = true;
      return await httpClient(error.config);
    } else {
      await AuthService.logout();
    }
  }

  return Promise.reject(error);
});

export default httpClient;
