



































































import moment from 'moment';
import Vue, { PropType } from 'vue';

enum Type {
  Date = 'date',
  Time = 'time',
  DateTime = 'datetime',
}

export default Vue.extend({
  name: 'cs-datetime-picker',
  props: {
    disabled: { type: Boolean, default: false },
    label: { type: String },
    value: { type: String },
    type: { type: String as PropType<Type>, default: Type.DateTime },
  },
  data: () => ({
    menu: false,
    activeTab: 0,
  }),
  watch: {
    menu() {
      this.activeTab = 0;
    },
  },
  computed: {
    displayDate() {
      switch (this.type) {
        case Type.Date:
          return (
            this.value && moment(this.value).toDate().toLocaleDateStringLS()
          );
        case Type.Time:
          return this.value;
        default:
          return (
            this.value && moment(this.value).toDate().toLocaleDateTimeStringLS()
          );
      }
    },
    date: {
      get() {
        return this.value && moment(this.value).toDate().toISODateString();
      },
      set(date: string) {
        const val = moment(date);
        const current = new Date(this.value);
        current.setFullYear(val.year(), val.month(), val.date());

        this.updateValue(current.toISOString());
      },
    },
    time: {
      get() {
        if (this.type === Type.Time) return this.value;

        return this.value && new Date(this.value).toShortTimeString();
      },
      set(time: string) {
        if (this.type === Type.Time) {
          this.updateValue(time);
          return;
        }

        const current = new Date(this.value);
        current.setHours(+time.split(':')[0], +time.split(':')[1]);

        this.updateValue(current.toISOString());
      },
    },
  },
  methods: {
    updateValue(val: string) {
      this.$emit('input', val);
    },
    done() {
      this.menu = false;
      this.$emit('done');
    },
    focus() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.input as any).focus();
      setTimeout(() => (this.menu = true), 1);
    },
  },
});
