






import { WidgetData } from '../../models/data';
import { WidgetType } from '../../models/widgetType';
import Vue, { PropType } from 'vue';
import LineChart from './chart_constructors/ChartLineConstructor.vue';

export default Vue.extend({
  name: 'ChartArea',
  components: { LineChart },
  props: {
    bodyData: {
      type: Object as PropType<WidgetData[WidgetType.ChartArea]>,
      required: true,
    },
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
      },
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              beginAtZero: true,
              display: true,
            },
          },
        ],
      },
    },
  }),
  computed: {
    data() {
      return {
        ...this.bodyData,
        datasets: this.bodyData.datasets.map((x) => ({
          ...x,
          fill: 'origin',
        })),
      };
    },
  },
});
