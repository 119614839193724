

































import Vue, { PropType } from 'vue';
import { NotificationData } from '../../services/NotificationService';

export default Vue.extend({
  name: 'cs-snackbar',
  props: {
    data: { type: Object as PropType<NotificationData> },
    offset: { type: Number },
    visible: { type: Boolean },
  },
  methods: {
    visibleChange() {
      this.close(false);
    },
    buttonClicked() {
      this.close(true);
    },
    remove() {
      this.close(false);
    },
    close(clicked: boolean) {
      this.$emit('close', clicked);
    },
  },
});
