









import { AuthService } from '@consolidate/shared/util-auth';
import Vue from 'vue';
import { getWidgets } from '../api/widget.api';
import { Widget } from '../models/widget';
import WidgetShell from './WidgetShell.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    WidgetShell,
  },
  data: () => ({
    widgets: [] as Widget[],
  }),
  beforeMount() {
    this.fetchWidgets();
  },
  methods: {
    logout() {
      AuthService.logout();
    },
    async fetchWidgets() {
      const { data } = await getWidgets();
      this.widgets = data.sort((a, b) => (a.order > b.order ? 1 : -1));
    },
  },
});
