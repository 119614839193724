export enum WidgetType {
  ClockAnalogue = 1,
  ClockDigital = 2,
  Weather = 3,
  Pdf = 4,
  Picture = 5,
  Website = 6,
  ListWithHeaders = 7,
  ListWithoutHeaders = 8,
  Value = 9,
  Map = 10,
  ChartLine = 11,
  ChartPie = 12,
  ChartBar = 13,
  ChartArea = 14,
}

export const getWidgetTypeName = (type: WidgetType) => WidgetType[type];

export const widgetTypeTranslations: {
  [key: number]: { name: string; description?: string };
} = {
  [WidgetType.ClockAnalogue]: { name: 'Uhr Analog' },
  [WidgetType.ClockDigital]: { name: 'Uhr Digital' },
  [WidgetType.Weather]: { name: 'Wetter' },
  [WidgetType.Pdf]: { name: 'Datei PDF' },
  [WidgetType.Picture]: { name: 'Datei Bild' },
  [WidgetType.Website]: { name: 'Webseite' },
  [WidgetType.ListWithHeaders]: {
    name: 'Liste mit Spaltenüberschriften',
    description: 'Spaltennamen werden als überschriften verwendet',
  },
  [WidgetType.ListWithoutHeaders]: { name: 'Liste ohne Spaltenüberschriften' },
  [WidgetType.Value]: { name: 'Wert' },
  [WidgetType.Map]: { name: 'Kartendarstellung' },
  [WidgetType.ChartLine]: {
    name: 'Diagramm Linie',
    description: 'Spaltennamen werden als legende verwendet.',
  },
  [WidgetType.ChartPie]: { name: 'Diagramm Kreis' },
  [WidgetType.ChartBar]: {
    name: 'Diagramm Balken',
    description: 'Spaltennamen werden als legende verwendet.',
  },
  [WidgetType.ChartArea]: {
    name: 'Diagramm Fläche',
    description: 'Spaltennamen werden als legende verwendet.',
  },
};
